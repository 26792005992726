#prgdpr-ccpa-form {
  max-width: 32.5rem;
  border: 0;
  background-color: #f4fbfe;
  padding: 2rem;
  box-shadow: 0px 2px 4px 0 rgba(22, 45, 57, 0.2);
}

.ccpa-form-wrapper .action-wrapper {
  margin-top: 2rem;
}
.ccpa-form-wrapper .field {
  margin-bottom: 1rem;
}